























































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import TagList from '@bertazzoni/back/components/TagList.vue'
import { User } from '@bertazzoni/common/src/models/user.model'

@Component({
  components: { Loader, TagList }
})
export default class UserConsultation extends Vue {
  private currentUser: User = new User()
  private pageLoaded = false

  async created(): Promise<void> {
    this.currentUser = await UsersService.findOne(this.$route.params.id)
    this.pageLoaded = true
  }
}
